$font-family-sans-serif: ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
// $font-family-sans-serif: Comic Sans MS, Comic Sans, cursive;

$primary: #0159b8;

$focus-ring-opacity: 0;

.m-h-100vh {
  min-height: 100vh;
}

.pt-6 {
  padding-top: 3.6rem;
}

.pt-7 {
  padding-top: 4.2rem;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  min-height: 100vh;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';